import { MAX_COUNT_LABELS_TO_SHOW, MAX_COUNT_LABELS_TO_SHOW_MOBILE } from "constants/Format";
import TalentModel from "models/talents/Talent";
import * as React from "react";
import TalentLandscapeCardDesktop from "./TalentLandscapeCardDesktop";
import TalentLandscapeCardMobile from "./TalentLandscapeCardMobile";
import { TalentLandscapeCardActions } from "./typing";
import { maxTablet } from "stylesheet/breakpoints/utils";
import Breakpoints from "stylesheet/breakpoints";
import { UserProfileContext } from "@context";

export const TalentLandscapeCard = (props: TalentModel & TalentLandscapeCardActions) => {
	const { feature, info } = props;
	const { userProfile } = React.useContext(UserProfileContext);

	const handleOnDeleteTalent = () => {
		const { id, onDeleteTalent } = props;
		if (onDeleteTalent && id) {
			onDeleteTalent(id);
		}
	};

	const handleOnUpdateTalent = () => {
		const { id, onUpdateTalent } = props;
		if (onUpdateTalent && id) {
			onUpdateTalent(id);
		}
	};

	return (
		<>
			<Breakpoints minWidth={maxTablet}>
				<TalentLandscapeCardDesktop
					userId={userProfile.id}
					talent={props}
					sectors={feature.sectors.slice(0, MAX_COUNT_LABELS_TO_SHOW)}
					schools={feature.schools.slice(0, MAX_COUNT_LABELS_TO_SHOW)}
					description={info.description}
					onDeleteTalent={handleOnDeleteTalent}
					onUpdateTalent={handleOnUpdateTalent}
				/>
			</Breakpoints>
			<Breakpoints maxWidth={maxTablet}>
				<TalentLandscapeCardMobile
					userId={userProfile.id}
					talent={props}
					sectors={feature.sectors.slice(0, MAX_COUNT_LABELS_TO_SHOW_MOBILE)}
					schools={feature.schools.slice(0, MAX_COUNT_LABELS_TO_SHOW_MOBILE)}
					description={info.description}
					onDeleteTalent={handleOnDeleteTalent}
					onUpdateTalent={handleOnUpdateTalent}
				/>
			</Breakpoints>
		</>
	);
};
