import { Translation } from "i18n/config";
import { TalentCardTranslation } from "./typing";

export const TalentCardTexts: Translation<TalentCardTranslation> = {
	es: {
		salary: "Salario Bruto (USD)",
		equity: "Capital",
		minimunExperience: "Experiencia mínima",
		positionLevel: "Rol",
		years: "año/s",
		seeAppliers: "Mirar aplicantes",
		edit: "Editar",
		delete: "Eliminar",
	},
	en: {
		salary: "Gross salary (USD)",
		equity: "Equity",
		minimunExperience: "Minimun experience",
		positionLevel: "Role",
		years: "year/s",
		seeAppliers: "See appliers",
		edit: "Edit",
		delete: "Delete",
	},
	ca: {
		salary: "Salari Brut (USD)",
		equity: "Equitat",
		minimunExperience: "Experiència mínima",
		positionLevel: "Rol",
		years: "any/s",
		seeAppliers: "Vegeu aplicadors",
		edit: "Edita",
		delete: "Suprimeix",
	},
	pt: {
		salary: "Salário líquido (USD)",
		equity: "Capital próprio",
		minimunExperience: "Experiência mínima",
		positionLevel: "Função",
		years: "ano/s",
		seeAppliers: "Veja os candidatos",
		edit: "Editar",
		delete: "Eliminar",
	},
};
