import { WhiteWrapper } from "elements/WhiteWrapper/WhiteWrapper";

import { TalentCardTexts } from "i18n/components/TalentCard";
import { TalentCardTranslation } from "i18n/components/TalentCard/typing";
import Link from "next/link";
import * as React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import { TalentLandscapeCardDummieProps } from "./typing";
import LikeButton from "components/Interactions/LikeButton";
import useReceiverTalentLikes from "hooks/interactions/talent/useReceiverTalentLikes";
import QuillGetTextWithoutHTMLNoSSR from "elements/InternalFormTextarea/QuillGetTextWithoutHTMLNoSSR";
import { MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ADVISER_LANDSCAPE } from "constants/Format";
import {useLocales} from "@libs";

function TalentLandscapeCardDesktopFeature(props: { fieldName: string; fieldValue: string }) {
	const { fieldName, fieldValue } = props;

	return (
		<h4 className="font-color-white-grey margin-0_5-y font-light">
			{fieldName}: <b className="font-color-grey font-bold">{fieldValue}</b>
		</h4>
	);
}

const TalentLandscapeCardDesktop = (props: TalentLandscapeCardDummieProps) => {
	const { userId, talent, sectors, schools, description, onDeleteTalent, onUpdateTalent } = props;
	const { loading: loadingLikes, numberOfLikes } = useReceiverTalentLikes(userId, talent.id!!);
	const { currentShortLocale } = useLocales();
	const translations: TalentCardTranslation = {
		...TalentCardTexts[currentShortLocale],
	};

	return (
		<WhiteWrapper
			wrapperMainClassName="padding-0-top"
			className="TalentLandscapeCardDesktop"
		>
			{{
				header: (
					<Grid className="padding-1-top">
						<Link
							href={talent.getInternalURL(currentShortLocale)}
							passHref
						>
							<a>
								<Image
									className="TalentLandscapeCardDesktop--image"
									wrapped
									{...talent.image}
									verticalAlign="middle"
								/>
							</a>
						</Link>
						<header className="TalentLandscapeCardDesktop--intro padding-0_5-top">
							<h2 className="margin-0-bottom">
								<Link
									href={talent.getInternalURL(currentShortLocale)}
									passHref
								>
									<a>{talent.title}</a>
								</Link>
							</h2>
							<Link
								href={talent.getOwnerURL()}
								passHref
							>
								<a>
									<Icon name="suitcase" /> {talent.getOwnerFullName()}
								</a>
							</Link>
							<p className="font-color-white-grey">
								<Icon name="map marker alternate" />
								{talent.info.location}, {talent.info.country.text}
							</p>
							{!talent.published && <Label content="Guardado como Borrador" />}
						</header>
					</Grid>
				),
				main: (
					<section className="full-width padding-1_5-x padding-2-y">
						<p>
							<QuillGetTextWithoutHTMLNoSSR
								rawText={description}
								maxLength={MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ADVISER_LANDSCAPE}
							/>
						</p>
						<TalentLandscapeCardDesktopFeature
							fieldName={translations.salary}
							fieldValue={"$" + props.talent.requirements.getShorterSalary()}
						/>
						<TalentLandscapeCardDesktopFeature
							fieldName={translations.equity}
							fieldValue={talent.requirements.getEquityUIText()}
						/>
						<TalentLandscapeCardDesktopFeature
							fieldName={translations.minimunExperience}
							fieldValue={`${talent.requirements.minExperience} ${translations.years}.`}
						/>
						<TalentLandscapeCardDesktopFeature
							fieldName={translations.positionLevel}
							fieldValue={talent.info.role ? talent.info.role.text : ""}
						/>
						<section className={"TalentLandscapeCardDesktop--features"}>
							<div>
								<ul className="padding-0-left margin-1-top">
									{sectors.map((item) => (
										<Label
											basic
											key={item.id}
											color="blue"
										>
											{item.text}
										</Label>
									))}
								</ul>
								<ul className="padding-0-left margin-1-top">
									{schools.map((item) => (
										<Label
											basic
											key={item.id}
											color="grey"
										>
											{item.text}
										</Label>
									))}
								</ul>
							</div>
							<aside className={"TalentLandscapeCardDesktop--wrapper-like-button"}>
								<LikeButton
									orientation={"vertical-mode"}
									size={"small"}
									initialNumberOfLikes={numberOfLikes}
									loading={loadingLikes}
								/>
							</aside>
						</section>
						{talent.isMine && (
							<section className="margin-2-top">
								<Link
									href={talent.getInternalAppliersURL(currentShortLocale)}
									passHref
								>
									<Button
										as="a"
										basic
										icon="list alternate"
										content={translations.seeAppliers}
									/>
								</Link>
								<Button
									positive
									basic
									icon={"pencil"}
									content={translations.edit}
									onClick={onUpdateTalent}
								/>
								<Button
									negative
									basic
									icon={"trash"}
									content={translations.delete}
									onClick={onDeleteTalent}
								/>
							</section>
						)}
					</section>
				),
			}}
		</WhiteWrapper>
	);
};

export default React.memo(TalentLandscapeCardDesktop);
