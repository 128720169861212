import React, { useEffect } from "react";
import { BrowserUtils } from "@utils";

const TEMP_QUILL_ID = "#temp-react-quill-editor";

interface QuillGetTextWithoutHTMLNoSSRProps {
	rawText: string;
	maxLength: number;
}

export const getTextFromQuillHTML = (rawText: string, maxLength?: number): Promise<string> => {
	if (BrowserUtils.isRunningOnBrowser() && document) {
		// @ts-ignore
		return import("quill").then((Quill) => {
			const editorHTML = document.createElement("div");
			editorHTML.id = TEMP_QUILL_ID;
			editorHTML.innerHTML = rawText;
			document.body.appendChild(editorHTML);

			const container = document.getElementById(TEMP_QUILL_ID);

			const quillEditor = new Quill.default(container!!);
			const textContent = quillEditor.getText(0, maxLength);

			editorHTML.parentElement?.removeChild(editorHTML);

			return textContent;
		});
	} else {
		return Promise.resolve("");
	}
};

const QuillGetTextWithoutHTMLNoSSR = (props: QuillGetTextWithoutHTMLNoSSRProps) => {
	const [text, setText] = React.useState("");

	useEffect(() => {
		getTextFromQuillHTML(props.rawText, props.maxLength)
			.then((textContent) => setText(textContent))
			.catch(() => setText(""));
	}, [props.maxLength, props.rawText]);

	if (!BrowserUtils.isRunningOnBrowser()) {
		return <div />;
	}

	return <>{text}</>;
};

export default QuillGetTextWithoutHTMLNoSSR;
