import * as React from "react";
import { getIfTheUserHasLikedTheTalentAPI } from "api/InteractionAPI/LikeAPI";
import { CookiesUtils } from "@utils";

const useTalentMyLikeChecker = (userId?: string, talentId?: string) => {
	const [likeState, setState] = React.useState({
		hasMyLike: false,
	});

	React.useEffect(() => {
		const accessToken = CookiesUtils.getAccessTokenFromCookie();

		if (userId && talentId) {
			getIfTheUserHasLikedTheTalentAPI(userId, talentId, accessToken)()
				.then(({ hasUserLiked }) => {
					setState({
						hasMyLike: hasUserLiked,
					});
				})
				.catch(() => {
					setState({
						hasMyLike: false,
					});
				});
		} else {
			setState({
				hasMyLike: false,
			});
		}
	}, []);

	return likeState;
};

export default useTalentMyLikeChecker;
