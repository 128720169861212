import * as React from "react";
import { getLikesFromTalentIdAPI } from "api/InteractionAPI/LikeAPI";
import useTalentMyLikeChecker from "hooks/interactions/talent/useTalentMyLikeChecker";

const useReceiverTalentLikes = (userId?: string, talentId?: string) => {
	const { hasMyLike } = useTalentMyLikeChecker(userId, talentId);
	const [likeState, setState] = React.useState({
		numberOfLikes: 0,
		loading: true,
	});

	React.useEffect(() => {
		if (talentId) {
			getLikesFromTalentIdAPI(talentId)()
				.then(({ totalLikes }) => {
					setState({
						loading: false,
						numberOfLikes: totalLikes || 0,
					});
				})
				.catch(() => {
					setState({
						loading: false,
						numberOfLikes: 0,
					});
				});
		}
	}, []);

	return {
		...likeState,
		hasMyLike,
	};
};

export default useReceiverTalentLikes;
