import { WhiteWrapper } from "elements/WhiteWrapper/WhiteWrapper";

import { TalentCardTexts } from "i18n/components/TalentCard";
import { TalentCardTranslation } from "i18n/components/TalentCard/typing";
import Link from "next/link";
import * as React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import { TalentLandscapeCardDummieProps } from "./typing";
import QuillGetTextWithoutHTMLNoSSR from "elements/InternalFormTextarea/QuillGetTextWithoutHTMLNoSSR";
import { MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ADVISER_LANDSCAPE } from "constants/Format";
import {useLocales} from "@libs";

const TalentLandscapeCardMobile = (props: TalentLandscapeCardDummieProps) => {
	const { talent, sectors, schools, description, onDeleteTalent, onUpdateTalent } = props;
	const { currentShortLocale } = useLocales();
	const translations: TalentCardTranslation = {
		...TalentCardTexts[currentShortLocale],
	};

	return (
		<WhiteWrapper
			wrapperMainClassName="padding-0-top"
			className="TalentLandscapeCardMobile"
		>
			{{
				header: (
					<Grid className="padding-1-top">
						<Link
							href={talent.getInternalURL(currentShortLocale)}
							passHref
						>
							<a className="TalentLandscapeCardMobile--ClickableImage">
								<Image
									className="TalentLandscapeCardMobile--image"
									wrapped
									{...talent.image}
									fluid
									verticalAlign="middle"
								/>
							</a>
						</Link>
						<header className="TalentLandscapeCardMobile--intro padding-2-top">
							<h2 className="margin-0-bottom">
								<Link
									href={talent.getInternalURL(currentShortLocale)}
									passHref
								>
									<a>{talent.title}</a>
								</Link>
							</h2>
							<Link
								href={talent.getOwnerURL()}
								passHref
							>
								<a>
									<Icon name="suitcase" /> {talent.getOwnerFullName()}
								</a>
							</Link>
							<p className="font-color-white-grey">
								<Icon name="map marker alternate" />
								{talent.info.location}, {talent.info.country.text}
							</p>
							{!talent.published && <Label content="Guardado como Borrador" />}
						</header>
					</Grid>
				),
				main: (
					<section className="padding-1_5-x padding-2-y">
						<p>
							<QuillGetTextWithoutHTMLNoSSR
								rawText={description}
								maxLength={MAX_LENGTH_PARAGRAPH_TO_DISPLAY_ADVISER_LANDSCAPE}
							/>
						</p>
						<li className="font-color-white-grey font-light">
							{translations.salary}:{" "}
							<b className="font-color-grey font-bold">${talent.requirements.getShorterSalary()}</b>
						</li>
						<li className="font-color-white-grey font-light">
							{translations.equity}:{" "}
							<b className="font-color-grey font-bold">{talent.requirements.getEquityUIText()}</b>
						</li>
						<li className="font-color-white-grey font-light">
							{translations.minimunExperience}:{" "}
							<b className="font-color-grey font-bold">
								{talent.requirements.minExperience} {translations.years}.
							</b>
						</li>
						<li className="font-color-white-grey font-light">
							{translations.positionLevel}: <b className="font-color-grey font-bold">{talent.info.role.text}</b>
						</li>
						<div className="margin-2-top">
							<ul className="padding-0-left margin-1-top">
								{sectors.map((item) => (
									<Label
										basic
										key={item.id}
										color="blue"
									>
										{item.text}
									</Label>
								))}
							</ul>
							<ul className="padding-0-left margin-1-top">
								{schools.map((item) => (
									<Label
										basic
										key={item.id}
										color="grey"
									>
										{item.text}
									</Label>
								))}
							</ul>
						</div>
						{talent.isMine && (
							<section className="margin-1-top">
								<Link
									href={talent.getInternalAppliersURL(currentShortLocale)}
									passHref
								>
									<Button
										as="a"
										basic
										fluid
										className="margin-1-top"
										icon="list alternate"
										content={translations.seeAppliers}
									/>
								</Link>
								<Button
									positive
									basic
									fluid
									className="margin-1-top"
									icon={"pencil"}
									content={translations.edit}
									onClick={onUpdateTalent}
								/>
								<Button
									negative
									basic
									fluid
									className="margin-1-top"
									icon={"trash"}
									content={translations.delete}
									onClick={onDeleteTalent}
								/>
							</section>
						)}
					</section>
				),
			}}
		</WhiteWrapper>
	);
};

export default React.memo(TalentLandscapeCardMobile);
